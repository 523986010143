'use client';

import { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { useGhostStore } from '@/legacy/store';

// Add window.Intercom to window type declaration
declare global {
  interface Window {
    Intercom?(name: string, options?: InitIntercomMessengerOptions): void;
  }
}

interface InitIntercomMessengerOptions {
  api_base: string;
  app_id: string;
  email: string;
  // user_hash: string; // ENG-2274
  name: string;
  user_id: number;
}

interface Props {
  /** TODO: Replace with LD feature flag `intercom-messenger` once LD is working. */
  isIntercomMessengerEnabled: boolean;
}

export const IntercomInit = ({ isIntercomMessengerEnabled }: Props) => {
  const currentUser = useGhostStore((state) => state.user);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [hasInitialized, setHasInitialized] = useState(false);

  // initialization
  useEffect(() => {
    if (isIntercomMessengerEnabled && !hasInitialized && currentUser) {
      const initIntercomInterval = setInterval(() => {
        if (window.Intercom) {
          window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'v46aom3u', // also in <RootLayout />
            email: currentUser.email,
            name: currentUser.firstName,
            // user_hash: currentUser.intercomHash, // ENG-2274
            user_id: currentUser.id,
          });
          setHasInitialized(true);
          clearInterval(initIntercomInterval);
        }
      }, 500);
    }
  }, [currentUser, hasInitialized, isIntercomMessengerEnabled]);

  // update intercom with page/url change
  useEffect(() => {
    window.Intercom?.('update');
  }, [pathname, searchParams]);

  return null;
};
