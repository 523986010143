import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentInit"] */ "/app/src/analytics/SegmentInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ThemeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/datadog/DatadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomInit"] */ "/app/src/helpdesk/IntercomInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DirtyContextProvider"] */ "/app/src/hooks/useBeforeUnload/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmDialogProvider"] */ "/app/src/legacy/common/confirm/useConfirm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/app/src/legacy/common/snackbar/useSnackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/legacy/LegacyPageWrapper.tsx");
